import React, { useState, useEffect } from 'react'

interface IframePDFViewerProps {
    text: string
}

const IframePDFViewer: React.FC<IframePDFViewerProps> = ({ text }) => {
    const [iframeSrc, setIframeSrc] = useState<string | null>(null)
    const [iframeTitle, setIframeTitle] = useState<string>('')

    useEffect(() => {
        const updatePDFViewer = async () => {
            try {
                const response = await fetch('/api/create-pdf/', {
                    method: 'POST',
                    body: text,
                })

                const contentDisposition = response.headers.get(
                    'Content-Disposition'
                )
                if (!contentDisposition) {
                    setIframeTitle('')
                } else {
                    const filename = contentDisposition.split('filename=')[1]
                    setIframeTitle(filename)
                }

                const blob = await response.blob()
                const url = URL.createObjectURL(blob)
                setIframeSrc(url)

                // Cleanup function for revoking the blob URL
                return () => {
                    URL.revokeObjectURL(url)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        updatePDFViewer()
    }, [text])

    return (
        <>
            {iframeSrc && <iframe src={iframeSrc} title={iframeTitle}></iframe>}
        </>
    )
}

export default IframePDFViewer
