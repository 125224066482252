import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as Tone from 'tone'
import * as Sentry from '@sentry/react'
import { ParsedPiece } from './core/ParsedPiece.tsx'
import { SelectedPlayBackItemDesc } from './core/LineMap.tsx'
import deepEqual from 'deep-equal'
import { ToneAudioBuffersUrlMap } from 'tone'
interface AudioComponentProps {
    parsedPiece: ParsedPiece | null
    selectedPlaybackItemDesc: SelectedPlayBackItemDesc | null
    doPlayAudio: boolean
}
function AudioComponentFunc({
    parsedPiece,
    selectedPlaybackItemDesc,
    doPlayAudio,
}: AudioComponentProps) {
    const [audioUnlocked, setAudioUnlocked] = useState(false)

    useEffect(() => {
        const unlockAudio = () => {
            if (Tone.context.state === 'running') {
                setAudioUnlocked(true)
                return
            }

            console.log(Tone.context.state)
            Tone.start()
                .then(() => {
                    console.log('Audio is ready')
                    setAudioUnlocked(true)
                })
                .catch((e) => {
                    console.error('There was an error starting the audio:', e)
                    setAudioUnlocked(false)
                    Sentry.captureException(e)
                })
        }

        // Set up the event listeners for click, keydown, and touchstart events
        document.documentElement.addEventListener('click', unlockAudio, {
            once: true,
        })
        document.documentElement.addEventListener('keydown', unlockAudio, {
            once: true,
        })
        document.documentElement.addEventListener('touchstart', unlockAudio, {
            once: true,
        })

        // Clean up the event listeners when the component unmounts
        return () => {
            document.documentElement.removeEventListener('click', unlockAudio)
            document.documentElement.removeEventListener('keydown', unlockAudio)
            document.documentElement.removeEventListener(
                'touchstart',
                unlockAudio
            )
        }
    }, [audioUnlocked])

    const [tonePlayers, setTonePlayers] = useState<Tone.Players | null>(null)

    useEffect(() => {
        if (!audioUnlocked || !parsedPiece) {
            // setTonePlayers(null)
            return
        }
        if (Tone.context.state === 'suspended') {
            console.error(
                'audio unlocked status boolean out of sync with tone.js state'
            )
            Sentry.captureMessage(
                'audio unlocked status boolean out of sync with tone.js state'
            )

            // setTonePlayers(null)
            return
        }

        const getSamplePath = (noteName: string): string => {
            return `/samples/${parsedPiece.speech_soundpack}/${noteName}.webm`
        }

        const playerUrls: ToneAudioBuffersUrlMap =
            parsedPiece.sample_names.reduce((acc, currentString: string) => {
                // @ts-expect-error ????
                acc[currentString] = getSamplePath(currentString)
                return acc
            }, {})

        console.log('creating player with urls:', playerUrls)

        const player = new Tone.Players(playerUrls, () => {
            console.log('All samples loaded successfully.')
            console.log('Available voices', parsedPiece.speech_soundpacks)
            setTonePlayers(player)
        }).toDestination()
    }, [parsedPiece, audioUnlocked])

    useEffect(() => {
        console.log('Players:', tonePlayers)
    }, [tonePlayers])

    const playbackItem = useMemo(() => {
        if (!parsedPiece) {
            return null
        }

        const speech_by_voices = parsedPiece.speech_by_voices
        if (!selectedPlaybackItemDesc) {
            return null
        }
        for (const candidate of speech_by_voices) {
            if (
                candidate.name == selectedPlaybackItemDesc.element_name &&
                candidate.instrument == selectedPlaybackItemDesc.instrument_name
            ) {
                return candidate
            }
        }

        return null
    }, [selectedPlaybackItemDesc, parsedPiece])

    const loop = useMemo(() => {
        if (!tonePlayers || !playbackItem || !parsedPiece) {
            return null
        }

        // Define your BPM
        const bpm = parsedPiece.bpm

        // console.log(bpm)

        // Calculate seconds per whole note (4 quarter notes)
        const secondsPerWholeNote = 60.0 / bpm

        // Function to convert quarter note time to seconds
        function convertToSeconds(quarterNoteTime: number): number {
            return quarterNoteTime * secondsPerWholeNote
        }

        const l = new Tone.Part(
            (time, value) => {
                try {
                    tonePlayers.player(value.note).start(time)
                } catch (e) {
                    console.log(e)
                    Sentry.captureException(e)
                }
                // console.log('Playing:', value.note)
            },
            playbackItem.data.map((event) => ({
                time: convertToSeconds(event.time),
                note: event.note,
            }))
        )
        l.debug = true
        l.loopEnd = convertToSeconds(playbackItem.duration)
        l.loopStart = 0
        l.loop = true
        return l
    }, [playbackItem, tonePlayers, parsedPiece])

    const previousLoop = useRef(loop)

    useMemo(() => {
        // stop previous if playback is supposed to stop or the loop changed
        const previous = previousLoop.current
        if (previous && previous !== loop && previous.state === 'started') {
            previous.stop()
            previous.dispose()
            // console.log('stopped previous loop')
        }
        previousLoop.current = loop

        // current loop
        if (doPlayAudio && loop && loop.state === 'stopped') {
            Tone.Transport.stop()
            Tone.Transport.seconds = 0
            loop.start(0)
            // console.log('started loop')
            Tone.Transport.start()
        }

        // dealing with transport
        if (!doPlayAudio && Tone.Transport.state === 'started') {
            Tone.Transport.stop()
            Tone.Transport.seconds = 0
            // console.log('stopped transport')
        }
        if (doPlayAudio && Tone.Transport.state !== 'started') {
            Tone.Transport.start()
            // console.log('started transport')
        }
    }, [doPlayAudio, loop, previousLoop])

    return <></>
}

const areEqual = (
    prevProps: AudioComponentProps,
    nextProps: AudioComponentProps
) => {
    return deepEqual(prevProps, nextProps)
}
export const AudioComponent = React.memo(AudioComponentFunc, areEqual)
